<template>
  <SuccessBanner
    :key="key"
    :msg="successMsg"
    :active="activeBanner"
    @closeBanner="activeBanner = false"
    :isSuccess="isSuccess"
  />
  <div class="flex flex-col ">
    <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
      <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
        <div
          class="shadow overflow-hidden border-b border-gray-200 rounded-t-md bg-white justify-center"
        >
          <div
            class="lg:flex lg:items-center lg:justify-between m-5 justify-center"
          >
            <div class="flex-1 min-w-0">
              <h2
                class="text-xl font-medium leading-7 body__header--text sm:text-3xl sm:truncate"
              >
                Infrastructures
              </h2>
            </div>
            <div class="mt-5 flex lg:mt-0 lg:ml-4 inline-flex">
              <SearchFilter
                v-model:search="search"
                :inputPlaceholder="$t('header.searchTicketPlaceHolder')"
              />
              <span class="hidden sm:block mt-1 ml-1 capitalize">
                <button
                  @click="openNewInfraView = !openNewInfraView"
                  type="button"
                  class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 encom_ring_color capitalize"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  infrastructure
                </button>
              </span>
            </div>
          </div>
          <div class="pl-10 pr-10 mt-1">
            <NewInfrastructure
              @closeForm="openNewInfraView = false"
              v-if="openNewInfraView"
              @closeHostView="openNewInfraView = false"
              @activeBanner="
                (activeBanner = true), getInfrastructures(), (key = !key)
              "
              @successMsg="successMsg = $event"
              @success="isSuccess = $event"
            />
          </div>
          <div class="pl-10 pr-10 mt-1">
            <EditInfrastructure
              @closeForm="openInfraView = false"
              :key="infrastructureId"
              v-if="openInfraView && infrastructureId"
              :infrastructureId="infrastructureId"
              @closeHostView="openInfraView = false"
              @activeBanner="
                (activeBanner = true), getInfrastructures(), (key = !key)
              "
              @successMsg="successMsg = $event"
              @success="isSuccess = $event"
            />
          </div>

          <div class="p-1 mt-1">
            <TablePagination
              @openEditForm="openEditInfrastructure($event)"
              :openInfraView="openInfraView"
              @openInfra="openEditInfrastructure($event)"
              :tableData="filteredInfrastructures()"
              rows="20"
              @sort="sort($event)"
              @activeBanner="
                (activeBanner = true), getInfrastructures(), (key = !key)
              "
              @successMsg="successMsg = $event"
              @success="isSuccess = $event"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import TablePagination from "../../components/TablePagination.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import ViewHost from "../../components/hosting/ViewHost.vue";
import SearchFilter from "../../components/SearchFilter.vue";
import EditInfrastructure from "../../components/hosting/EditInfrastructure.vue";
import NewInfrastructure from "../../components/hosting/NewInfrastructure.vue";

export default {
  title() {
    return `Infrastructure - ${localStorage.getItem("title")}`;
  },
  props: [""],
  components: {
    TablePagination,
    SuccessBanner,
    ViewHost,
    SearchFilter,
    EditInfrastructure,
    NewInfrastructure,
  },
  data() {
    return {
      currentSort: "id",
      currentSortDir: "asc",
      successMsg: "",
      activeBanner: false,
      isSuccess: true,
      key: false,
      infrastructureId: null,
      infrastructures: [],
      openInfraView: false,
      openNewInfraView: false,
      search: "",
    };
  },
  methods: {
    openEditInfrastructure(id) {
      this.infrastructureId = id;
      this.openInfraView = !this.openInfraView;
    },
    async getInfrastructures() {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/infrastructures`
        );
        console.log(res.data);
        this.infrastructures = res.data;
      } catch (error) {
        this.errorHandling(error);
      }
    },

    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      }
      this.currentSort = s;
    },
    sortedInfrastructures() {
      return this.infrastructures.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === "desc") modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    },
    filteredInfrastructures() {
      return this.sortedInfrastructures().filter((infrastructure) => {
        return (
          infrastructure.name
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          infrastructure.code.includes(this.search)
        );
      });
    },
  },
  mounted() {
    this.getInfrastructures();
  },
};
</script>

<style></style>
