<template>
  <div
    class="bg-white border border-gray-300 overflow-hidden sm:rounded-lg w-1/3"
    v-if="host"
  >
    <div class="px-4 py-2 sm:px-6 flex justify-between">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Host <span class="font-bold">{{ host.code }}</span>
      </h3>
      <button
        @click="$emit('closeHostView')"
        class="p-1 border-2 border-gray-600 rounded-xl hover:bg-gray-100"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
    </div>
    <div class="border-t border-gray-200 px-4 py-2 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div class="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Name
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ host.name }}
          </dd>
        </div>
        <div class="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Ip Address
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ host.address }}
          </dd>
        </div>
        <div class="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Customer
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <a
              class="cursor-pointer underline"
              @click="this.$router.push(`/customer-detail/${host.customer_id}`)"
              >{{ customerName }}</a
            >
          </dd>
        </div>
        <div class="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Host type
          </dt>
          <dd
            v-if="hostType"
            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          >
            {{ hostType.name }}
          </dd>
        </div>
        <div class="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Host role
          </dt>
          <dd
            v-if="hostRole"
            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          >
            {{ hostRole.name }}
          </dd>
        </div>
        <div class="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Shell type
          </dt>
          <dd
            v-if="shellType"
            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          >
            {{ shellType.name }}
          </dd>
        </div>
        <div class="py-4 sm:py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="text-sm font-medium text-gray-500">
            Host OS
          </dt>
          <dd
            v-if="hostOS"
            class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          >
            {{ hostOS.name }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
  <loading
    v-model:active="isLoading"
    :can-cancel="false"
    :on-cancel="onCancel"
    :is-full-page="fullPage"
  />
</template>

<script>
import axios from "axios";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  props: ["hostId"],
  data() {
    return {
      isLoading: true,
      fullPage: false,
      host: {},
      customerName: undefined,
      customerId: null,
      contactId: null,
      hostRole: undefined,
      hostType: undefined,
      shellType: undefined,
      hostOS: undefined,
    };
  },
  components: { Loading },
  methods: {
    async getHost(id) {
      try {
        const res = await axios.get(
          `${this.$cookie.getCookie("API")}/api/v1/hosts/${id}`
        );
        const customer = await this.getCustomer(res.data.customer_id);
        console.log("customer", customer);
        this.host = res.data;
        console.log(res.data);
        this.customerId = res.data.customer_id;
        this.customerName = res.data.customer.name;
        this.contactId = customer.contacts[0].id;
        this.hostRole = res.data.host_role;
        this.hostType = res.data.host_type;
        this.hostShell = res.data.host_shell;
        this.hostOS = res.data.host_os;
        this.isLoading = false;
      } catch (error) {
        this.errorHandling(error);
        this.isLoading = false;
      }
    },
    async getCustomer(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/customers/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    async getRole(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/hostRoles/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    async getType(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/hostTypes/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    async getShell(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/hostShells/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
    async getOS(id) {
      if (id) {
        try {
          const res = await axios.get(
            `${this.$cookie.getCookie("API")}/api/v1/hostOs/${id}`
          );
          return res.data;
        } catch (error) {
          this.errorHandling(error);
        }
      }
    },
  },
  mounted() {
    this.getHost(this.hostId);
  },
};
</script>

<style></style>
